import "./Navbar.scss";
import React, { Component } from 'react';
import { NavLink } from "react-router-dom";

export default class Navbar extends Component {

    constructor() {
        super();
        if (/(iP(hone|od|ad)|Mac)/.test(navigator.userAgent)) {
            document.body.classList.add("apple");
        }

        // please note,
        // that IE11 now returns undefined again for window.chrome
        // and new Opera 30 outputs true for window.chrome
        // but needs to check if window.opr is not undefined
        // and new IE Edge outputs to true now for window.chrome
        // and if not iOS Chrome check
        // so use the below updated condition
        const isChromium = window.chrome;
        const winNav = window.navigator;
        const vendorName = winNav.vendor;
        const isOpera = typeof window.opr !== "undefined";
        const isIEedge = winNav.userAgent.indexOf("Edg") > -1;
        const isIOSChrome = winNav.userAgent.match("CriOS");

        if (isIOSChrome) {
            // is Google Chrome on IOS
        } else if (
            isChromium !== null &&
            typeof isChromium !== "undefined" &&
            vendorName === "Google Inc." &&
            isOpera === false &&
            isIEedge === false
        ) {
            document.body.classList.add("chrome");
        }
    }

    render() {
        return (
            <div className="navbar">
                <div>
                    <div>
                        <NavLink to="/">
                            <div className="title">
                                <div>🫖</div>
                                <div>Thee Verrassing</div>
                            </div>
                        </NavLink>
                    </div>
                </div>
            </div>
        );
    }
}
