import './App.css';
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Imprint from "./components/Imprint/Imprint";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import { useState } from "react";

function App() {

    // refresh trigger on translation change:
    const [count, setCount] = useState(0);

    return (
        <Router>
            <ScrollToTop>
                <div className="root-container">
                    <Navbar rerenderParentCallback={() => setCount(count + 1)}/>
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route path="/imprint" element={<Imprint/>}/>
                        <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                    </Routes>
                    <Footer/>
                </div>
            </ScrollToTop>
        </Router>
    );
}

export default App;
