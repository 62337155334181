import "./Home.scss";
import React, { Component } from 'react';

export default class Home extends Component {

    constructor(props) {
        super(props);
        this.state = { state: "START" };
    }

    surprise() {
        const selectionIndex = parseInt((Math.random() * 12).toString());
        const rowIndex = parseInt((selectionIndex / 4).toString());
        const cellIndex = selectionIndex % 4;
        const rows = document.getElementsByClassName("row");
        const row = rows.item(rowIndex);
        const cells = row.getElementsByClassName("cell");
        const cell = cells.item(cellIndex);
        cell.className = "cell selected";
        this.setState(state => {
            state.state = "SELECTION";
            state.rowIndex = rowIndex;
            state.cellIndex = cellIndex;
            return state;
        });
    }

    reset() {
        const rows = document.getElementsByClassName("row");
        for (const row of rows) {
            const cells = row.getElementsByClassName("cell");
            for (const cell of cells) {
                cell.className = "cell";
            }
        }


        this.setState(state => {
            state.state = "START";
            return state;
        });
    }

    getButtons() {
        if (this.state.state === "START") {
            return <button className="go-button" onClick={() => this.surprise()}>🎁 VERRAS ME!</button>;
        } else if (this.state.state === "SELECTION") {
            let name = "";
            let icon = "";
            switch (this.state.rowIndex) {
                case 0:
                    switch (this.state.cellIndex) {
                        case 0:
                            icon = "🇹🇷";
                            name = "Turkse appel";
                            break;
                        case 1:
                            icon = "🇪🇸";
                            name = "Spaanse sinaasappel";
                            break;
                        case 2:
                            icon = "🇮🇷";
                            name = "Perzische granaatappel";
                            break;
                        case 3:
                            icon = "🇲🇦";
                            name = "Marokkaanse munt";
                            break;
                        default:
                            name = "???";
                            break;
                    }
                    break;
                case 1:
                    switch (this.state.cellIndex) {
                        case 0:
                            icon = "🇹🇭";
                            name = "Thaise gembermango";
                            break;
                        case 1:
                            icon = "";
                            name = "Mediterrane perzik";
                            break;
                        case 2:
                            icon = "🇮🇹";
                            name = "Italiaanse limoen";
                            break;
                        case 3:
                            icon = "🌿";
                            name = "Oosterse kruidenthee";
                            break;
                        default:
                            name = "???";
                            break;
                    }
                    break;
                case 2:
                    switch (this.state.cellIndex) {
                        case 0:
                            icon = "🇨🇳";
                            name = "Chinees Sencha";
                            break;
                        case 1:
                            icon = "🇮🇳";
                            name = "Indiase chai";
                            break;
                        case 2:
                            icon = "🇺🇸";
                            name = "New York Chai";
                            break;
                        case 3:
                            icon = "🇯🇵";
                            name = "Japans Kersenbloesemfestival";
                            break;
                        default:
                            name = "???";
                            break;
                    }
                    break;
                default:
                    name = "?";
                    break;
            }
            return <div className="result-container">
                <div className="result">
                    {icon}
                    <span style={{ padding: "12px" }}></span>
                    {name}
                </div>
                <button className="go-button" onClick={() => this.reset()}>↻ OPNIEUW</button>
            </div>;
        }
    }

    render() {

        return (
            <div className="home-container">
                <div className="teafield">
                    <div className="row">
                        <div className="cell">🫖</div>
                        <div className="cell">🫖</div>
                        <div className="cell">🫖</div>
                        <div className="cell">🫖</div>
                    </div>
                    <div className="row">
                        <div className="cell">🫖</div>
                        <div className="cell">🫖</div>
                        <div className="cell">🫖</div>
                        <div className="cell">🫖</div>
                    </div>
                    <div className="row">
                        <div className="cell">🫖</div>
                        <div className="cell">🫖</div>
                        <div className="cell">🫖</div>
                        <div className="cell">🫖</div>
                    </div>
                </div>
                <div className="go-button-container">
                    {this.getButtons()}
                </div>
                <div className="meer-informatie">
                    ⌄ Meer Informatie beneden ⌄
                </div>
                <div className="its-a-teapot">
                    🫖
                </div>
            </div>
        );
    }
}
