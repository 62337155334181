import "./Footer.scss";
import React, { Component } from 'react';
import { Link } from "react-router-dom";

export default class Footer extends Component {
    render() {
        return (
            <div className="footer">
                <div>Huisgemaakt met ♥ en ✨</div>
                <div>© 2022 Henning Wobken.</div>
                <div>Alle rechten voorbehouden.</div>
                <div>
                    <Link to="imprint">Afdruk</Link>
                    <Link to="privacy-policy">Privacy</Link>
                </div>

            </div>
        );
    }
}
